<template>
  <div>
    <Navbar />
    <div id="main-content">
      <b-sidebar id="ProfileSidebar" :lazy="true" backdrop no-header>
        <ProfileSubnav></ProfileSubnav>
      </b-sidebar>
      <b-container>
        <b-row>
          <b-col class="d-none d-xl-block" xl="3">
            <ProfileSubnav></ProfileSubnav>
          </b-col>
          <b-col xl="9">
            <router-view />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    Navbar: () => import("../default/Navbar.vue"),
    Footer: () => import("../default/Footer.vue"),
    ProfileSubnav: () => import("./ProfileSubnav.vue"),
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
  },
};
</script>

<style lang="scss" scoped>
#main-content {
  padding-bottom: 338px;

  @media screen and (max-width: 767px) {
    padding-bottom: 76vh;
  }
}
</style>
